import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../home/home";
import Services from "../services/services";
import About from "../about/about";
import Projects from "../projects/projects";
import Contact from "../contact/contact";
import Header from "../../components/header/header";
import FixedButon from "../../components/fixed_button/fixedButon";
import ListsOfProjects from "../listsOfProjects/listsOfProjects";
import JoinUs from "../../components/joinUs/joinUs";
import ProductOwner from "../productOwner/productOwner";
import WorkWithUs from "../../components/workWithUs/workWithUs";
import Footer from "../../components/footer/footer";
import Comingsoon from "../../components/comingsoon/comingsoon";
import SmartBuilding from "../ourSolutions/smartBuilding";
import Sustainability from "../ourSolutions/sustainability";
import HubSpot from "../ourSolutions/hubSpot";
import Hosting from "../ourSolutions/hosting";
import PrivacyPolicy from "../privacy/privacy";
import ScrollToTop from "./scrollTop";
import TermsandConditions from "../../components/termsandconditions/termsandconditions";
import BlogPage from "../../components/blog/blog";

import NewyearAderco from "../../components/newyear/aderco";
import NewyearAgc from "../../components/newyear/agc";
import NewyearBefutura from "../../components/newyear/befutura";
import NewyearCjh from "../../components/newyear/cjh";
import NewyearGaelens from "../../components/newyear/gaelens";
import NewyearHub from "../../components/newyear/hub";
import NewyearIxelles from "../../components/newyear/ixelles";
import NewyearOptiflux from "../../components/newyear/optiflux";
import NewyearSpw from "../../components/newyear/spw";
import NewyearTop from "../../components/newyear/top";
import NewyearVito from "../../components/newyear/vito";
import NewyearVvsg from "../../components/newyear/vvsg";
import UsecaseAlt from "../usecase-alt/usecase-alt";
import UsecaseAlt2 from "../usercase2-alt/usecase2-alt";
import UsecaseRenocity from "../usecase-renocity/usecase-renocity";

const CustomRoutes = () => {
  const currentPath = window.location.pathname;
  return (
    <div>
    {currentPath === '/usecase/optiflux' 
    || currentPath === '/usecase/aderco'  
    || currentPath === '/usecase/renocity' 
    ? (
      <Router>
        <Routes>
          <Route path="/usecase/optiflux" element={<UsecaseAlt />} />
          <Route path="/usecase/aderco" element={<UsecaseAlt2 />} />
          <Route path="/usecase/renocity" element={<UsecaseRenocity />} />
        </Routes>
      </Router>
    ) : (
      <Router>
        <ScrollToTop />
        <Header />
        <FixedButon />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/how-we-do-it" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<ListsOfProjects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project-detail/:slug" element={<Projects />} />
          <Route path="/join-us" element={<JoinUs />} />
          <Route path="/smart-building" element={<SmartBuilding />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/hubspot" element={<HubSpot />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/hosting" element={<Hosting />} />
          <Route path="/work-with-us" element={<WorkWithUs />} />
          <Route path="/product-owner" element={<ProductOwner />} />
          <Route path="/comingsoon" element={<Comingsoon />} />
          <Route path="/terms" element={<TermsandConditions />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route
            path="/newyear-0ca8b117f8e66e7a12419dcb63bf25213b8e62f5cab2bde8d681bc3b691a5012-vvsg"
            element={<NewyearVvsg />}
          />
          <Route
            path="/newyear-739eaa87f4efe4913f9ddf3a25902f61d67b4794-agc"
            element={<NewyearAgc />}
          />
          <Route
            path="/newyear-9efb565a6b76e668f43608464703f8a4d3ebddfc-befutura"
            element={<NewyearBefutura />}
          />
          <Route
            path="/newyear-25bf3547e61105101a5bc75fbb2a146660e27ae3-vito"
            element={<NewyearVito />}
          />
          <Route
            path="/newyear-f38b0587bea3e88343678f6bf85aa40cc4599749-cjh"
            element={<NewyearCjh />}
          />
          <Route
            path="/newyear-abea09b644a1f5a115752622241e498d626c0178-gaelens"
            element={<NewyearGaelens />}
          />
          <Route
            path="/newyear-f1e5974bdd237df3db84c4520b08b4dece308aa2-optiflux"
            element={<NewyearOptiflux />}
          />
          <Route
            path="/newyear-c207a684c44aa877b2dce292c680cb104324ae8d-spw"
            element={<NewyearSpw />}
          />
          <Route
            path="/newyear-4fb331ebcddd480a4e9918750cd33433621d66a1-topinterieur"
            element={<NewyearTop />}
          />
          <Route
            path="/newyear-d0c2794436896d7ea59d64f4ee80016dff8894dc-aderco"
            element={<NewyearAderco />}
          />
          <Route
            path="/newyear-65acf0a7ced564a4880cf946224e60b745d3d631-hub"
            element={<NewyearHub />}
          />
          <Route
            path="/newyear-9dce06878e3a3f94cf2c2094d335d68139364707-ixelles"
            element={<NewyearIxelles />}
          />
        </Routes>
        <Footer />
      </Router>
    )}
    </div>
  );
};
export default CustomRoutes;
